import { VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/lib/utils';

const fundingProgressBarVariants = cva('', {
  variants: {
    size: {
      default: 'h-2',
      large: 'h-4',
    },
    background: {
      light: 'bg-primary-50',
      white: 'bg-white',
      green: 'bg-green-300',
    },
  },
  defaultVariants: {
    size: 'default',
    background: 'light',
  },
});

export interface FundingProgressBarProps
  extends VariantProps<typeof fundingProgressBarVariants> {
  current: number;
  total: number;
  simulateMargin?: boolean;
}

const FundingProgressBar = ({
  current,
  total,
  size = 'default',
  background = 'light',
  simulateMargin = true,
}: FundingProgressBarProps) => {
  const barPercentage = (current / total) * 100;
  const barWidthPercentage = simulateMargin
    ? Math.min(barPercentage, 92)
    : Math.min(barPercentage, 100);
  return (
    <div
      className={cn(
        'relative w-full rounded-full',
        fundingProgressBarVariants({ size, background }),
      )}
    >
      <span
        className={cn(
          'absolute inset-y-0 rounded-full',
          barPercentage > 100 ? 'bg-success' : 'bg-primary',
          size ? { default: 'min-w-2', large: 'min-w-4' }[size] : null,
        )}
        style={{
          width: `${barWidthPercentage}%`,
        }}
      />
    </div>
  );
};
FundingProgressBar.displayName = 'FundingProgressBar';

export { FundingProgressBar, fundingProgressBarVariants };
